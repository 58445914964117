@import "libs/includes";
@import "libs/reset";
body {
    font-family: 'Open Sans'
}
a {
    color: inherit;
}
.button-center {
    text-align: center;
    a {
        text-decoration: none;
    }
}

.button {
    background-color: #3d3935;
    border: solid 4px #3d3935;
    color: #fff;
    cursor: pointer;
    font-size: 14px;
    padding: 10px 50px;
    position: relative;
    text-decoration: none;
    text-transform: uppercase;
    &:after {
        @include absolute(0 null null 0);
        @include size(calc(100% - 6px));
        @include transition(all $ease-in-out-cubic 150ms);
        border: solid 3px #3d3935;
        content: '';
    }
    &:hover:after {
        border: solid 3px #8ea294;
    }
}

.to-top {
    @include fixed(null 5% 30px null);
    @include size(50px);
    @include border-bottom-radius(50%);
    @include border-top-radius(50%);
    @include transition(all $ease-in-out-cubic 250ms);
    background-color: #cac9c8;
    border: solid 1px #a19d9a;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.3);
    color: #3d3935;
    cursor: pointer;
    opacity: 0;
    padding-top: 7px;
    text-align: center;
    text-decoration: none;
    z-index: 50;
    &.show {
        opacity: 1
    }
    .icon {
        font-size: 1.2em
    }
    .text {
        font-size: .7em;
        text-transform: uppercase
    }
    &:hover {
        background-color: #fff;
        color: #3d3935
    }
}

.header {
    @include clear-after;
    background-image: url("../imgs/header.png");
    padding: 40px 0;
    .navigation {
        float: left;
        padding: 20px 50px;
    }
    .tagline {
        color: #ffe9bf;
        font-size: 18px;
        font-style: italic;
        padding: 8px 0;
    }
    .nav a {
        @include transition(background-color $ease-in-out-cubic 250ms);
        background-color: #8ea294;
        display: block;
        float: left;
        font-size: 15px;
        margin-left: 12px;
        margin-top: 8px;
        overflow: hidden;
        padding: 8px 0;
        position: relative;
        text-align: center;
        text-decoration: none;
        text-transform: uppercase;
        width: 90px;
        &:hover {
            background-color: #fff
        }
        &:first-of-type {
            margin-left: 0
        }
    }
}

@include resize-to(phone) {
    .header {
        padding: 0;
        padding-top: 14px;
        .logo {
            display: block;
            margin: 0 auto
        }
        .navigation {
            float: none;
            padding: 0;
        }
        .tagline {
            padding: 28px 0;
            text-align: center;
        }
        .nav a {
            margin: 0;
            padding: 18px 10px;
            width: 25%;
        }
    }
}
@include resize-to(desktop) {
    .header {
        .logo {
            float: left;
        }
    }
}

.page-content {
    @include clear-after;
    margin: 0 auto;
    max-width: 900px;
    &.white {
        background-color: #fff;
    }
}

.hero {
    background-attachment: fixed;
    background-image: url("../imgs/hero.jpg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 400px;
    padding: 42px 12px;
    position: relative;
    .tagline {
        color: #ffe9bf;
        font-size: 48px;
        font-weight: 800;
        text-align: center;
        text-shadow: 0 5px 2px rgba(0, 0, 0, 0.8);
        text-transform: uppercase;
    }
    .source-link {
        position: absolute;
        right: 10px;
        bottom: -18px;
        color: #69635d;
        font-size: 10px;
        text-transform: uppercase;
    }
}

.about {
    padding: 0 14px;
    .title {
        font-size: 28px;
        font-weight: 700;
        padding: 60px 0 40px;
        text-transform: uppercase;
    }
    .text {
        line-height: 24px;
        margin-bottom: 16px;
        &:last-of-type {
            margin-bottom: 0
        }
    }
    .button-center {
        padding: 50px 0;
    }
}

.section-header {
    color: #3d3935;
    font-size: 2em;
    font-weight: 500;
    padding: 65px 0;
    position: relative;
    text-align: center;
    &:before {
        @include absolute(50% null null 30px);
        border-bottom: solid 1px #a19d9a;
        content: '';
        margin-top: 1px;
        width: 30%;
    }
    &:after {
        @include absolute(50% 30px null null);
        border-bottom: solid 1px #a19d9a;
        content: '';
        margin-top: 1px;
        width: 30%;
    }
}

@include resize-to(phone) {
    .section-header {
        padding: 24px 0;
        &:before {
            opacity: 0;
        }
        &:after {
            opacity: 0;
        }
    }
}

.testimonials {
    padding: 60px 14px;
    .testimonials-icon {
        @include border-bottom-radius(50%);
        @include border-top-radius(50%);
        @include size(70px);
        background-color: #fff;
        color: #8ea294;
        font-size: 2.4em;
        line-height: 68px;
        margin: 0 auto;
        text-align: center;
    }
    .testimonials-list {
        line-height: 26px;
        padding-top: 40px;
        text-align: justify;
    }
    &.pond-and-stream {
        background-image: url('../imgs/pondandstream.png');
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        color: #FFF;
        text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.3);
        .testimonials-list {
            padding: 0;
        }
        p {
            margin: 0 auto;
            max-width: 700px;
        }
    }
    &.big-country {
        background-color: #8ea294;
        font-family: "Oleo Script";
        font-size: 20px;
    }
}

.stones-and-boulders {
    background-color: #d2cdbe;
    padding-bottom: 40px;
    .stone-item {
        float: left;
        margin-bottom: 24px;
        position: relative;
        width: calc(100% / 3);
        &:hover .stone-text {
            opacity: 1
        }
    }
    .stone-title {
        color: #3d3935;
        font-size: 1.1em;
        font-style: italic;
        text-align: center;
        text-transform: uppercase
    }
    .stone-text {
        @include transition(opacity $ease-in-out-cubic 150ms);
        background-color: rgba(0, 0, 0, 0.8);
        color: #fff;
        font-size: .9em;
        font-weight: 300;
        line-height: 1.6em;
        opacity: 0;
        padding: 20px;
        text-align: center
    }
    .stone-image {
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        height: 225px;
        margin-top: 2px
    }
}

@include resize-to(phone) {
    .stones-and-boulders {
        .stone-item {
            float: none;
            margin-left: 12px;
            width: calc(100% - 24px);
        }
        .stone-text {
            opacity: 1;
        }
    }
}
@include resize-to(desktop) {
    .stones-and-boulders .stone-text {
        @include position(relative, 0 null null 0);
        @include size(100%);
    }
}

.services {
    background-attachment: fixed;
    background-image: url("../imgs/services.jpg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 0 24px;
    position: relative;
    .page-content {
        padding: 0 20px 50px;
    }
    .service-row {
        .service-title {
            font-size: 1.1em;
            font-weight: 600;
            padding: 8px 26px;
            position: relative;
            text-transform: uppercase;
            &:before {
                @include absolute(8px null null 0);
                @include size(18px);
                background-position: center;
                background-repeat: no-repeat;
                background-size: contain;
                content: '';
            }
            &.locating:before {
                background-image: url(../imgs/icons/locating.jpg);
            }
            &.excavation:before {
                background-image: url(../imgs/icons/excavation.jpg);
            }
            &.shipment:before {
                background-image: url(../imgs/icons/shipment.jpg);
            }
            &.install:before {
                background-image: url(../imgs/icons/installation.jpg);
            }
        }
        .service-text {
            line-height: 24px;
            margin-bottom: 36px;
        }
    }
    .source-link {
        @include absolute(null 10px -18px null);
        color: #69635d;
        font-size: 10px;
        text-transform: uppercase;
    }
}

.contact {
    background-color: #d2cdbe;
    padding-bottom: 50px;
    .telephone-wrapper, .email-wrapper {
        @include transition(color $ease-in-out-cubic 150ms);
        color: #69635d;
        cursor: pointer;
        float: left;
        text-align: center;
        width: 50%;
        &:hover {
            color: #8ea294;
        }
    }
    .email {
        float: left;
        padding-left: 50px;
    }
    .telephone {
        float: right;
        padding-right: 50px;
    }
    .icon {
        font-size: 5em;
    }
}

.recaptcha-wrapper {
    @include fixed(0 null null 0);
    @include size(100%);
    z-index: 105;
}

.recaptcha-wrapper .recaptcha-content {
    @include border-bottom-radius(3px);
    @include border-top-radius(3px);
    background-color: #fff;
    box-shadow: 0 0 50px 30px rgba(0, 0, 0, 0.3);
    height: 132px;
    margin: 5% auto 0;
    padding: 14px;
    width: 330px;
    .recaptcha-text {
        padding-bottom: 14px;
        text-align: center;
    }
}
.footer {
    background-image: url("../imgs/header.png");
    color: #ffe9bf;
    font-size: 12px;
    padding: 26px 0;
    text-align: center;
    .button {
        padding: 0;
    }
}
